const pad = (num: number, length: number) => {
  let s = num + '';
  while (s.length < length) {
    s = `0${s}`;
  };
  return s;
};

export const formatCredits = (balance: number, textOnly: boolean = false) => {
  const isNegative = balance < 0;
  balance = Math.abs(balance);
  const dollars = Math.floor(balance / 100_000_000);
  const cents = Math.floor(balance / 1_000_000) % 100;
  const microcents = balance % 1_000_000;
  return textOnly
    ? `${isNegative ? '(-' : ''}\$${dollars}.${pad(cents, 2)}${isNegative ? ')' : ''}`
    : (
      <span className="credit-balance">
        {isNegative && <span className="left-bracket">{"(-"}</span>}
        <span className="currency">$</span>
        <span className="dollars">{dollars}</span>
        <span className="split">.</span>
        <span className="cents">{pad(cents, 2)}</span>
        <span className="microcents">{pad(microcents, 6)}</span>
        {isNegative && <span className="right-bracket">{")"}</span>}
      </span>
    );
};

export const formatCurrency = (cents: number) => {
  const dollars = Math.floor(cents / 100);
  cents = cents % 100;
  return `$${dollars}.${pad(cents, 2)}`;
};