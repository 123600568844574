import { useState, useEffect, useRef } from "react";
import { useParams, useLoaderData, Link } from "react-router-dom";

import { File } from "react-feather";
import {
  DetailedPackageDeploymentSchema,
  DetailedPackageFileSchema,
  PackageSchema
} from "../../utils/types";

import API, { ErrorWithDetails } from "../../utils/api";

import "./EditorPage.scss";

export default function EditorPage() {

  const editorRef = useRef(null);
  const cphRef = useRef<any>(null);

  useEffect(() => {
    if (!cphRef.current) {
      cphRef.current?.close();
      const editor = new window.Copenhagen.Editor({
        language: 'javascript',
        maximized: true
      });
      editor.treeView.show();
      editor.fileTabs.show();
      cphRef.current = editor;
      if (editorRef.current) {
        const editorEl = editorRef.current as HTMLDivElement;
        editor.open(editorEl.querySelector('.text-editor'));
        editor.treeView.open(editorEl.querySelector('.tree-view'));
        editor.fileTabs.open(editorEl.querySelector('.file-tabs'));
      }
    }
  }, []);

  return (
    <div data-component="EditorPage" ref={editorRef}>
      <div className="editor-left">
        <div className="tree-view" />
      </div>
      <div className="editor-right">
        <div className="file-tabs" />
        <div className="text-editor"/>
      </div>
    </div>
  );

};