import { ReactElement, Suspense, useMemo } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  LoaderFunction
} from 'react-router-dom';
import { useAuth } from './context/AuthContext';

import Nav from './components/nav/Nav';
import DashboardNav from './components/nav/DashboardNav';
import Footer from './components/nav/Footer';
import IndexPage from './pages/IndexPage';
import PricingPage from './pages/pricing/PricingPage';
import PricingPageLoader from './pages/pricing/PricingPageLoader';
import DashboardPage from './pages/DashboardPage';
import SignupPage from './pages/signup/SignupPage';
import LoginPage from './pages/signup/LoginPage';
import Login3pPage from './pages/signup/Login3pPage';
import SetupPage from './pages/setup/SetupPage';
import AgentsPage from './pages/agents/AgentsPage';
import BillingCreditsPage from './pages/billing/credits/BillingCreditsPage';
import BillingSubscriptionPage from './pages/billing/subscription/BillingSubscriptionPage';
import BillingSubscriptionPageLoader from './pages/billing/subscription/BillingSubscriptionPageLoader';
import BillingMethodsPage from './pages/billing/methods/BillingMethodsPage';
import BillingMethodsPageLoader from './pages/billing/methods/BillingMethodsPageLoader';
import PackagesPage from './pages/packages/PackagesPage';
import PackageDetailsPage from './pages/packages/PackageDetailsPage';
import PackageDetailsPageLoader from './pages/packages/PackageDetailsPageLoader';
import EditorPage from './pages/editor/EditorPage';
import ErrorPage from './pages/error/ErrorPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';

import Sidebar from './components/sidebar/Sidebar';

interface Page {
  path: string,
  element: ReactElement,
  sidebarElement?: ReactElement,
  loader?: LoaderFunction,
  requireLogin?: boolean,
  hideNav?: boolean,
  dashboardNav?: boolean,
  hideFooter?: boolean,
};

function RequireAuth({ children }: { children: ReactElement }) {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return children;
}

function RootPage() {
  const { user } = useAuth();
  if (user) {
    return <Navigate to="/dashboard" />;
  }
  return <IndexPage />;
}

const Sidebars = {
  Billing: [
    {
      url: '/billing',
      title: 'Subscription'
    },
    {
      url: '/billing/credits',
      title: 'Function credits'
    },
    {
      url: '/billing/methods',
      title: 'Payment methods'
    }
  ]
};

const Pages: Page[] = [
  {
    path: "/",
    element: <RootPage />,
  },
  /**
   * hideNav pages: we want the user to finish this task
   */
  {
    path: "/login",
    hideNav: true,
    element: <LoginPage />,
  },
  {
    path: "/signup",
    hideNav: true,
    element: <SignupPage />,
  },
  {
    path: "/login-3p",
    hideNav: true,
    element: <Login3pPage mode="login" />,
  },
  {
    path: "/setup",
    hideNav: true,
    element: (
      <RequireAuth>
        <SetupPage />
      </RequireAuth>
    ),
  },
  /**
   * Navigable pages: Global
   */
  {
    path: "/pricing",
    element: <PricingPage />,
    loader: PricingPageLoader(),
  },
  {
    path: "/packages",
    element: <PackagesPage />,
  },
  {
    path: "/packages/private/:org/:name/:environment?/*",
    element: <PackageDetailsPage />,
    loader: PackageDetailsPageLoader({ isPrivate: true }),
  },
  {
    path: "/packages/:org/:name/:environment?/*",
    element: <PackageDetailsPage />,
    loader: PackageDetailsPageLoader({ isPrivate: false }),
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/terms-of-service",
    element: <TermsOfServicePage />,
  },
  /**
   * Navigable pages: Auth only
   */
  {
    path: "/link-3p",
    element: (
      <RequireAuth>
        <Login3pPage mode="link" />
      </RequireAuth>
    ),
  },
  {
    path: "/e",
    element: <EditorPage />,
    hideFooter: true
  },
  /**
   * Dashboard pages
   */
  {
    path: "/dashboard",
    dashboardNav: true,
    element: (
      <RequireAuth>
        <DashboardPage />
      </RequireAuth>
    ),
  },
  {
    path: "/agents",
    dashboardNav: true,
    element: (
      <RequireAuth>
        <AgentsPage />
      </RequireAuth>
    ),
  },
  {
    path: "/billing",
    loader: BillingSubscriptionPageLoader(),
    dashboardNav: true,
    sidebarElement: <Sidebar links={Sidebars.Billing} />,
    element: (
      <RequireAuth>
        <BillingSubscriptionPage />
      </RequireAuth>
    ),
  },
  {
    path: "/billing/credits",
    dashboardNav: true,
    sidebarElement: <Sidebar links={Sidebars.Billing} />,
    element: (
      <RequireAuth>
        <BillingCreditsPage />
      </RequireAuth>
    ),
  },
  {
    path: "/billing/methods",
    loader: BillingMethodsPageLoader(),
    dashboardNav: true,
    sidebarElement: <Sidebar links={Sidebars.Billing} />,
    element: (
      <RequireAuth>
        <BillingMethodsPage />
      </RequireAuth>
    ),
  },
  /**
   * Handle errors if nothing else is reached
   */
  {
    path: "/*",
    element: <ErrorPage />,
  }
];

function Layout({
  children,
  sidebarElement,
  hideNav,
  dashboardNav,
  hideFooter
}: {
  children: ReactElement,
  sidebarElement?: ReactElement,
  hideNav?: boolean,
  dashboardNav?: boolean,
  hideFooter?: boolean
}) {
  return (
    <>
      {!hideNav && <Nav />}
      {!!dashboardNav && <DashboardNav />}
      {(sidebarElement
        ? (
          <div className="page-content-with-sidebar">
            <div className="page-sidebar">
              {sidebarElement}
            </div>
            <div className="page-content">
              <Suspense fallback={<div>Loading...</div>}>
                {children}
              </Suspense>
            </div>
          </div>
        )
        : (
          <div className="page-content">
            <Suspense fallback={<div>Loading...</div>}>
              {children}
            </Suspense>
            {!hideNav && !hideFooter && <Footer />}
          </div>
        )
      )}
    </>
  );
}

function Router() {

  const router = useMemo(() => {
    return createBrowserRouter(Pages.map(page => {

      const elementWithLayout = (
        <Layout
          hideNav={page.hideNav}
          hideFooter={page.hideFooter}
          dashboardNav={page.dashboardNav}
          sidebarElement={page.sidebarElement}>
          {page.element}
        </Layout>
      );

      return {
        path: page.path,
        element: elementWithLayout,
        loader: page.loader,
        errorElement: (
          <Layout>
            <ErrorPage />
          </Layout>
        ),
      };
    }));
  }, []);

  return <RouterProvider router={router} />;

}

export default Router;
