import { UserSchema } from './types';

export type ErrorWithDetails = Error & {
  status?: number,
  pathname?: string,
  details?: {[key: string]: any},
  clear?: (key: string) => ErrorWithDetails | null
};

/**
 * ASCII to Unicode (decode Base64 to original data)
 */
export const atou = (b64: string) => {
  return decodeURIComponent(escape(atob(b64)));
}
/**
 * Unicode to ASCII (encode data to Base64)
 */
export const utoa = (data: string) => {
  return btoa(unescape(encodeURIComponent(data)));
}

class APIRequester {

  root: string | null;
  apiKey: string | null;
  user: UserSchema | null;
  userCallback: ((user: UserSchema | null) => void) | null;

  constructor(root: string) {
    this.root = root;
    this.apiKey = this.getLocalStorage('apiKey');
    this.user = this.getLocalStorage('user');
    this.userCallback = null;
  }

  setUserCallback (userCallback: (user: UserSchema | null) => void | null) {
    this.userCallback = userCallback;
  }

  setLocalStorage (key: string, value: any = null) {
    if (value === null) {
      localStorage.removeItem(`${this.root}:${key}`);
    } else {
      localStorage.setItem(`${this.root}:${key}`, utoa(JSON.stringify(value)));
    }
    return value;
  }

  getLocalStorage (key: string) {
    let json = null;
    try {
      const item = localStorage.getItem(`${this.root}:${key}`);
      if (item !== null) {
        json = JSON.parse(atou(item));
      }
    } catch (e) {
      const error = e as Error;
      console.error(
        `Could not load "${key}" from localStorage, invalid JSON\n`,
        error.message
      );
    }
    return json;
  }

  authenticate(apiKey: string) {
    this.apiKey = apiKey;
    this.setLocalStorage('apiKey', this.apiKey);
  }

  async login({
    username,
    password
  } : {   
    username: string,
    password: string
  }) {
    const params = {
      username,
      password,
      grant_type: 'password'
    };
    const loginResult = await this.post('auth', params);
    const key = loginResult?.accessTokens?.[0]?.key as string;
    if (!key) {
      throw new Error(`No key returned, unable to login`);
    }
    this.authenticate(key);
    return this.refreshUser();
  }

  async login3p({
    source,
    token
  } : {   
    source: "discord",
    token: string
  }) {
    const params = {token};
    const loginResult = await this.post(`auth/third_party/${source}`, params);
    const key = loginResult?.user.accessTokens?.[0]?.key as string;
    if (!key) {
      throw new Error(`No key returned, unable to login`);
    }
    this.authenticate(key);
    await this.refreshUser();
    return loginResult;
  }

  async refreshUser() {
    try {
      const [ userResult, subscriptionResult ] = await Promise.all([
        this.get('users/me'),
        this.get('payments/subscriptions')
      ]);
      this.user = userResult as UserSchema | null;
      if (this.user && subscriptionResult && subscriptionResult.currentPlan) {
        this.user.currentPlan = subscriptionResult.currentPlan;
      }
      this.setLocalStorage('user', this.user);
      this.userCallback && this.userCallback(this.user);
      return this.user;
    } catch (e) {
      const error = e as ErrorWithDetails;
      console.error(`Error fetching user: ${error.message}`);
    }
  }

  async logout() {
    try {
      await this.post('auth/logout');
      this.user = this.setLocalStorage('user', null);
      this.apiKey = this.setLocalStorage('apiKey', null);
      this.userCallback && this.userCallback(this.user);
    } catch (e) {
      console.error(e);
    }
  }

  async request(
    pathname: string,
    method: string,
    {
      requestHeaders = {},
      queryParams = {},
      params = {},
      jsonMode = false
    } : {
      requestHeaders?: {[key: string]: any},
      queryParams?: {[key: string]: any},
      params?: {[key: string]: any},
      jsonMode?: boolean
    }
  ): Promise<{text: string, json: {[key: string]: any} | null}> {
    if (pathname.startsWith('/')) {
      pathname = pathname.slice(1);
    }
    if (!pathname.endsWith('/')) {
      pathname = pathname + '/';
    }
    const headers = {...requestHeaders};
    headers['Content-Type'] = 'application/json';
    if (this.apiKey) {
      headers['Authorization'] = `Bearer ${this.apiKey}`;
    }
    const query: {[key: string]: any} = {};
    for (const key in queryParams) {
      if (queryParams[key] !== null && queryParams[key] !== void 0) {
        query[key] = queryParams[key];
      }
    }
    const querystring = Object.keys(query).map(key => {
      let value = query[key];
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      return [encodeURIComponent(key), encodeURIComponent(value)].join('=');
    }).join('&');
    const url = `${this.root}/${pathname}?${querystring}`;
    const body = Object.keys(params).length
      ? JSON.stringify(params)
      : ''
    if (body && (method === 'GET' || method === 'DELETE')) {
      throw new Error(`Can not send request body with GET or DELETE (${pathname})`);
    }
    const options: {[key: string]: any} = {
      method,
      headers
    };
    if (body) {
      options.body = body;
    }
    const response = await fetch(url, options);
    let text = null;
    let json = null;
    if (jsonMode || response.headers.get('content-type')?.split(';')[0] === 'application/json') {
      try {
        json = await response.json();
      } catch (e) {
        throw new Error(`Invalid JSON (${pathname}): ${(e as Error).message}`);
      }
      text = JSON.stringify(json);
    } else {
      text = await response.text();
    }
    if (response.status !== 200) {
      if (response.status === 401) {
        this.user = this.setLocalStorage('user', null);
        this.apiKey = this.setLocalStorage('apiKey', null);
        this.userCallback && this.userCallback(this.user);
      }
      const error = new Error(json?.error?.message || text) as ErrorWithDetails;
      error.status = response.status;
      error.pathname = pathname;
      if (json?.error?.details) {
        error.details = json.error.details;
        error.clear = function (key: string) {
          const cError = new Error(this.message) as ErrorWithDetails;
          cError.pathname = pathname;
          cError.details = JSON.parse(JSON.stringify(this.details));
          cError.clear = this.clear;
          if (cError.details && cError.details[key]) {
            delete cError.details[key];
          }
          if (Object.keys(cError.details || {}).length) {
            return cError;
          } else {
            return null;
          }
        };
      }
      throw error;
    }
    return {
      text,
      json
    };
  }

  async get(
    pathname: string,
    queryParams: {[key: string]: any} = {},
    requestHeaders: {[key: string]: any} = {}
  ) {
    const result = await this.request(pathname, 'GET', {queryParams, requestHeaders, jsonMode: true});
    return result.json;
  }

  async del(
    pathname: string,
    queryParams: {[key: string]: any} = {},
    requestHeaders: {[key: string]: any} = {}
  ) {
    const result = await this.request(pathname, 'DELETE', {queryParams, requestHeaders, jsonMode: true});
    return result.json;
  }

  async post(
    pathname: string,
    params: {[key: string]: any} = {},
    requestHeaders: {[key: string]: any} = {}
  ) {
    const result = await this.request(pathname, 'POST', {params, requestHeaders, jsonMode: true});
    return result.json;
  }

  async put(
    pathname: string,
    params: {[key: string]: any} = {},
    requestHeaders: {[key: string]: any} = {}
  ) {
    const result = await this.request(pathname, 'PUT', {params, requestHeaders});
    return result.json;
  }

}

const API = new APIRequester(process.env.REACT_APP_API_URL || '');

export default API;