import { useState, useEffect, useRef, ReactElement } from "react";
import { useNavigate, useLocation, useSearchParams, Link } from "react-router-dom";

import { AgentSchema, DiscordLinkSchema, PackageEndpointSchema, PackageSchema, StandaloneAgentSchema } from "../../utils/types";

import {
  Check,
  Plus,
  Save,
  X,
  Zap,
  Search,
  ArrowRight,
  RefreshCcw,
} from 'react-feather';
import { ReactComponent as DiscordLogo } from "../../svg/discord-mark-white.svg";
import { useAuth } from "../../context/AuthContext";
import Button from "../../components/button/Button";
import Select from "../../components/select/Select";
import Textbox from "../../components/textbox/Textbox";
import API, { ErrorWithDetails } from "../../utils/api";
import { sleep } from "../../utils/sleep";
import { getTimeDelta, formatRecent } from "../../utils/time";

import "./AgentsPage.scss";

const serialize = (obj: any) => {
  obj = obj || {};
  if (obj && typeof obj === "object") {
    return Object.keys(obj).sort().map(key => {
      return [key, obj[key]].join('=');
    }).join('&');
  } else {
    return obj;
  }
};

const formatDate = (d: Date) => {
  const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  };
  let months = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dev'.split(' ');
  let month = months[d.getMonth()];
  let day = d.getDate();
  let h = d.getHours();
  let p = h >= 12 ? 'PM' : 'AM';
  if (h > 12) {
    h = h % 12;
  }
  let m = d.getMinutes() + '';
  if (m.length === 1) {
    m = `0${m}`;
  }
  return `${month} ${day}${nth(day)} at ${h}:${m} ${p}`;
};

interface FunctionResult {
  package: {
    name: string,
    version: string,
    url: string
  },
  filename: string,
  arguments: string,
  method: string,
  microcredits: number,
  name: string,
  result: string,
  url: string
}

interface ChatMessageEntry {
  name?: string,
  time?: Date | string | null,
  app?: boolean,
  message?: string,
  functions?: FunctionResult[]
};

function ChatMessage({
  name = "User",
  time = null,
  app = false,
  message = "",
  functions = []
} : ChatMessageEntry) {
  time = new Date(time || Date.now());

  const messageParts: ReactElement[] = message
    .split(/(^|\s)(https?\:\/\/[^\s]+)/gi)
    .map((text, i) => {
      if (text.startsWith('http://') || text.startsWith('https://')) {
        if (text.endsWith('.')) {
          return <span key={i}><a href={text.slice(0, -1)} target="_blank">{text.slice(0, -1)}</a>.</span>;
        } else {
          return <span key={i}><a href={text} target="_blank">{text}</a></span>;
        }
      } else {
        return <span key={i}>{text}</span>;
      }
    });

  return (
    <div data-component="ChatMessage">
      <div className="chat-heading hidden"></div>
      <div className="chat-body">
        <div className={app ? `chat-avatar app` : `chat-avatar`}>
          {(name[0] || "A").toUpperCase()}
        </div>
        <div className="chat-content">
          <div className="chat-author">
            <div className="chat-author-name">{name}{app && <span className="app">APP</span>}</div>
            <div className="chat-time">{formatDate(time)}</div>
          </div>
          <div className="chat-message">
            {messageParts}
          </div>
          {!!functions.length && (
            <div className="functions">
              {functions.map((f, i) => {
                let args = f.arguments;
                let result = f.result;
                try {
                  args = JSON.stringify(JSON.parse(args), null, 2);
                } catch (e) {
                  // do nothing
                }
                try {
                  result = JSON.stringify(JSON.parse(result), null, 2);
                } catch (e) {
                  // do nothing
                }
                return (
                  <div className="function-details" key={i}>
                    <span className="function-name">
                      <Zap />
                      <Link
                        to={`${f.package.url}/${f.filename}`}
                        target={`_blank`}
                        >
                        {f.package.name}/{f.package.version}<br />
                        {f.filename}
                      </Link>
                    </span>
                    <pre>{args}</pre>
                    <pre>{result}</pre>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default function AgentsPage() {

  const location = useLocation();
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();
  const { user, organization } = useAuth();

  const [agent, setAgent] = useState<AgentSchema | null>(null);
  const [botName, setBotName] = useState("");
  const [botPrompt, setBotPrompt] = useState("");
  const [botPackages, setBotPackages] = useState<{[key: string]: string}>({});

  const [isSearching, setIsSearching] = useState(false);
  const [packageSearch, setPackageSearch] = useState("");
  const [packageEndpoints, setPackageEndpoints] = useState<Array<PackageEndpointSchema>>([]);
  const packageEndpointsRef = useRef(0);

  const scrollRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<ChatMessageEntry[]>([]);

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<ErrorWithDetails | null>(null);

  const fetchPackageEndpoints = async (delay = 300) => {
    setIsSearching(true);
    const id = ++packageEndpointsRef.current;
    await sleep(delay);
    if (id !== packageEndpointsRef.current) {
      return;
    }
    const endpointsResponse = await API.get(
      'v1/packages/endpoints/search',
      {query: packageSearch, limit: {count: 3, offset: 0}}
    );
    if (id !== packageEndpointsRef.current) {
      return;
    }
    const packageEndpoints = (endpointsResponse?.data || []) as Array<PackageEndpointSchema>;
    setIsSearching(false);
    setPackageEndpoints(packageEndpoints);
  };

  // Use the `location` object to trigger a re-render when the query string changes
  useEffect(() => {
    const agentId = searchParams.get('agent_id');
    if (agentId) {
      fetchPackageEndpoints(0);
      (async () => {
        try {
          const agentResponse = await API.get(
            'v1/agents',
            {
              organization: organization.name,
              includeDiscord: true
            }
          );
          const agents = (agentResponse?.data || []) as AgentSchema[];
          const agent = agents.find(a => a.unique_id === agentId);
          const botName = agent?.agentConfigs?.[0]?.name || "";
          const botPrompt = agent?.agentConfigs?.[0]?.system_prompt || "";
          const botPackages = agent?.agentConfigs?.[0]?.enabled_packages || {};
          setAgent(agent || null);
          setBotName(botName);
          setBotPrompt(botPrompt);
          setBotPackages(botPackages);
        } catch (e) {
          setError(e as ErrorWithDetails);
        }
      })();
    }
  }, [location.search]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages])

  const updateAgent = async () => {
    if (!agent) {
      return;
    }
    if (isSaving) {
      return;
    }
    setIsSaving(true);
    try {
      const agentResult = await API.put(
        "v1/agents",
        {
          id: agent.unique_id,
          name: botName,
          prompt: botPrompt,
          packages: botPackages,
          includeDiscord: true
        }
      );
      setAgent(agentResult as AgentSchema);
      setError(null);
    } catch (e) {
      setError(e as ErrorWithDetails);
    }
    setIsSaving(false);
  };

  const sendChatMessage = (value: string) => {
    const message: ChatMessageEntry = {
      name: organization.name,
      time: new Date(),
      app: false,
      message: value
    };
    setMessages(messages => messages.concat(message));
    if (agent) {
      (async () => {
        try {
          const queryResult = await API.post(
            "v1/agents/requests",
            {
              id: agent.unique_id,
              query: value
            }
          );
          const responseAgent = queryResult?.agent as StandaloneAgentSchema;
          const response = queryResult?.response || "";
          const functs = (queryResult?.functions || []) as FunctionResult[];
          if (response) {
            const responseMessage: ChatMessageEntry = {
              name: responseAgent.agentConfigs[0].name,
              time: new Date(),
              app: true,
              message: response,
              functions: functs
            };
            setMessages(messages => messages.concat(responseMessage));
          }
        } catch (e) {
          const error = e as Error;
          const responseMessage: ChatMessageEntry = {
            name: agent.agentConfigs[0].name,
            time: new Date(),
            app: true,
            message: error.message,
            functions: []
          };
          setMessages(messages => messages.concat(responseMessage));
        }
      })();
    }
  }

  return (
    <div data-component="AgentsPage">
      <div className="settings">
        <div className="scrollable">
          <div className="form-row">
            <Textbox
              heading="bot name"
              value={botName}
              onChange={botName => setBotName(botName)}
              />
          </div>
          <div className="discord-links">
            {agent?.discordAgents?.map(discordAgent => {
              const discordLink = discordAgent.discordLink;
              return (
                <div className="discord-link-entry" key={discordLink.unique_id}>
                  <div className="icon">
                    <ArrowRight />
                  </div>
                  <div className="info">
                    in
                    &nbsp;<Link
                      to={`https://discord.com/channels/${discordLink.discord_guild_id}`}
                      target="_blank"
                    >
                      {discordLink.discord_guild_metadata.name}
                    </Link>
                    &nbsp;as&nbsp;
                    <strong>
                      {discordLink.discord_bot_metadata?.nick || '(unknown)'}
                    </strong>
                  </div>
                  <div className="spacer" />
                  {discordLink.discord_bot_metadata?.update_rate_limited_until && (
                    getTimeDelta(discordLink.discord_bot_metadata.update_rate_limited_until).sign === -1
                      ? (
                          <div className="warn">
                            unsynced, wait {formatRecent(discordLink.discord_bot_metadata.update_rate_limited_until).replace('in ', '')}
                          </div>
                        )
                      : (
                          <div className="warn">
                            save to resync
                          </div>
                        )
                  )}
                </div>
              );
            })}
          </div>
          <div className="form-row">
            <Textbox
              heading="personality"
              type="multiline"
              lines={3}
              maxLines={8}
              value={botPrompt}
              onChange={botPrompt => setBotPrompt(botPrompt)}
              />
          </div>
          <div className="form-row">
            {Object.keys(botPackages).map(name => {
              const version = botPackages[name];
              return (
                <Button
                  key={`${name}/${version}`}
                  label={`${name}/${version}`}
                  icon={X}
                  color={"default"}
                  size="small"
                  onClick={() => {
                    const pkgs = {...botPackages};
                    delete pkgs[name];
                    setBotPackages(pkgs);
                  }}
                  />
              );
            })}
          </div>
          <div className="form-row">
            <Textbox
              heading="search for functs..."
              endIcon={Search}
              value={packageSearch}
              loading={isSearching}
              onChange={search => {
                setPackageSearch(search);
                fetchPackageEndpoints();
              }}
              />
          </div>
          <div className="form-row">
            <div className="package-endpoints">
              {packageEndpoints.map(packageEndpoint => {
                const info = packageEndpoint.function_info;
                return (
                  <div
                    key={packageEndpoint.function_info.name}
                    className="package-endpoint"
                    >
                    <div className="info">
                      <div className="title">
                        <span className="highlight">{info.summary.title}</span>
                        <span className="time">{formatRecent(packageEndpoint.created_at)}</span>
                      </div>
                      <div className="summary">
                        <span className={`method method-${info.method}`}>
                          {info.method}
                        </span>
                        <span>
                          {info.summary.identifier}{info.summary.endpoint ? '/' + info.summary.endpoint : ''}
                        </span>
                      </div>
                    </div>
                    <div className="spacer" />
                    <div className="action">
                      <Button
                        label={botPackages[info.package.name] === info.package.version ? `Added` : `Add`}
                        icon={botPackages[info.package.name] === info.package.version ? Check : Plus}
                        color={botPackages[info.package.name] === info.package.version ? "green" : "default"}
                        size="small"
                        onClick={() => {
                          const pkgs = {...botPackages};
                          if (pkgs[info.package.name] === info.package.version) {
                            delete pkgs[info.package.name];
                          } else {
                            pkgs[info.package.name] = info.package.version;
                          }
                          setBotPackages(pkgs);
                        }}
                        />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {error && (
            <div className="form-row margin-top error">
              {error.message}
            </div>
          )}
        </div>
        <div className="form-row margin-top fixed">
          <div className="spacer" />
          <Button
            icon={Save}
            color="orange"
            disabled={(
              botName === (agent?.agentConfigs[0].name || "") &&
              botPrompt === (agent?.agentConfigs[0].system_prompt || "") &&
              serialize(botPackages) === serialize(agent?.agentConfigs[0].enabled_packages)
            )}
            label="save changes"
            loading={isSaving}
            onClick={updateAgent}
            />
        </div>
      </div>
      <div className="chat">
        <div className="scrollable" ref={scrollRef}>
          <div className="welcome">
            Welcome to #test-channel!
          </div>
          <div className="description">
            This is not a real channel, but you can use it to test <mark>@{agent?.agentConfigs[0].name}</mark>.
          </div>
          {messages.map((message, i) => {
            return <ChatMessage key={i} {...message} />;
          })}
        </div>
        <div className="fixed">
          <Textbox
            type="chat"
            lines={1}
            maxLines={8}
            placeholder="Message #test-channel"
            onSubmit={value => sendChatMessage(value)}
            />
        </div>
      </div>
    </div>
  );

}