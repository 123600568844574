import { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import API, { ErrorWithDetails } from "../../utils/api";
import { formatCredits } from "../../utils/format";
import {
  Home,
  LogOut,
  CreditCard,
  DollarSign,
  AlertCircle
} from "react-feather";
import { isMobile } from "../../utils/device";

import "./Nav.scss";

export default function Nav() {

  const navigate = useNavigate();
  const { user, organization, wallet } = useAuth();

  const isMobileView = isMobile();

  const menuRef = useRef(null);
  const [isShowingMenu, setIsShowingMenu] = useState(false);

  const handleLogout = async (e: any) => {
    e.preventDefault();
    await API.logout();
    navigate('/');
  };

  const showMenu = () => {
    setIsShowingMenu(true);
  }
  const hideMenu = () => {
    setIsShowingMenu(false);
  };
  const handleDiscordLink = async (e: any) => {
    try {
      const result = await API.post('v1/discord_user_auths', {mode: 'link'});
      window.location = result?.url;
    } catch (e) {
      const error = e as ErrorWithDetails;
      alert(error.message);
    }
  };

  useEffect(() => {

    const hideMenuHandler = (e: any) => {
      const menuEl = menuRef.current;
      let isLink = false;
      let el = e.target;
      while (el) {
        if (el.tagName?.toLowerCase() === 'a') {
          isLink = true;
        }
        if (el === menuEl) {
          if (isLink) {
            // Allow link to click / onClick to execute
            window.requestAnimationFrame(() => hideMenu());
          }
          return;
        }
        el = el.parentNode;
      }
      hideMenu();
    };

    window.addEventListener('click', hideMenuHandler, true);

    return () => {
      window.removeEventListener('click', hideMenuHandler, true);
    };

  }, []);

  return (
    <div data-component="Nav">
      <span>
        <Link to={user ? `/dashboard` : `/`}>Funct</Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to="/packages">Packages</Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to="/pricing">Pricing</Link>
      </span>
      <span className="spacer" />
      {user && (
        <>
          <div
            className="user-info"
            onMouseEnter={() => !isMobileView && showMenu()}
            onMouseLeave={() => !isMobileView && hideMenu()}
            onMouseDown={() => isMobileView && showMenu()}
            ref={menuRef}
          >
            <div
              className="user-name"
            >
              {user.discordUser?.verified_at
                  ? <img className="icon" src="/icons/verified.png" />
                  : <AlertCircle className="icon" />
                }
              <span>{organization.name}</span>
            </div>
            {isShowingMenu && (
              <div className="user-menu-container">
                <ul className="menu user-menu">
                  <li>
                    <span className="center">{organization.email}</span>
                  </li>
                  <li>
                    <span>
                      {user.discordUser?.verified_at && (
                        <>
                          <img className="icon" src="/icons/verified.png" />
                          <span>Verified</span>
                          <span className="spacer" />
                          <a href="#" onClick={handleDiscordLink}>Re-verify</a>
                        </>
                      )}
                      {!user.discordUser?.verified_at && (
                        <>
                          <AlertCircle className="icon" />
                          <span>Unverified</span>
                          <span className="spacer" />
                          <a href="#" onClick={handleDiscordLink}>Link Discord</a>
                        </>
                      )}
                    </span>
                  </li>
                  <li>
                    <span>
                      <CreditCard className="icon" />
                      <span>
                        Plan: {user.currentPlan?.display_name}
                      </span>
                      <span className="spacer" />
                      <span>
                        <Link to="/billing">Upgrade</Link>
                      </span>
                    </span>
                  </li>
                  <li>
                    <span>
                      <DollarSign className="icon" />
                      <span>Function credits</span>
                      <span className="spacer" />
                      <span>
                        <Link to="/billing/credits">{formatCredits(wallet.microcredit_balance, true)}</Link>
                      </span>
                    </span>
                  </li>
                  <hr />
                  <li>
                    <Link to="/dashboard">
                      <Home className="icon" />
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/billing">
                      <CreditCard className="icon" />
                      <span>Billing</span>
                    </Link>
                  </li>
                  <hr />
                  <li>
                    <Link to="#" onClick={handleLogout}>
                      <LogOut className="icon" />
                      <span>Log out</span>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </>
      )}
      {!user && (
        <>
          <span>
            <Link to="/login">Log in</Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Link to="/signup">Sign up</Link>
          </span>
        </>
      )}
    </div>
  );

}