import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoaderData } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { BillingMethodsPageLoaderData } from "./BillingMethodsPageLoader";
import { PaymentMethodSchema } from "../../../utils/types";
import API from "../../../utils/api";

import { Trash, Check, Plus } from "react-feather";

import "./BillingMethodsPage.scss";
import Button from "../../../components/button/Button";

function PaymentMethodCard({
  paymentMethod
} : {
  paymentMethod: PaymentMethodSchema
}) {

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const card = paymentMethod.card;
  const isDefaultMethod = paymentMethod.metadata.is_default_method === 'true';
  const setDefaultMethod = async (paymentMethodId: string) => {
    setIsLoading(true);
    try {
      await API.put('payments/payment_methods', { paymentMethodId, isDefault: true });
      navigate('.', { replace: true });
      setIsLoading(false);
    } catch (e) {
      const error = e as Error;
      setIsLoading(false);
      alert(error.message);
    }
  };
  const removeMethod = async (paymentMethodId: string) => {
    setIsLoading(true);
    try {
      await API.del('payments/payment_methods', { paymentMethodId, isDefault: true });
      navigate('.', { replace: true });
      setIsLoading(false);
    } catch (e) {
      const error = e as Error;
      setIsLoading(false);
      alert(error.message);
    }
  };

  if (card) {

    const brand = card.display_brand[0].toUpperCase() + card.display_brand.slice(1);

    return (
      <div data-component="PaymentMethodCard">
        {brand} &bull;&bull;&bull;&bull; {card.last4}<br />
        Exp: {card.exp_month} / {card.exp_year}<br />
        {paymentMethod.billing_details.name}, {paymentMethod.billing_details.address.country}
        <div className="card-actions">
          <Button
            size="small"
            icon={isDefaultMethod ? Check : void 0}
            label={isDefaultMethod ? 'Default': 'Set as default'}
            disabled={isDefaultMethod}
            loading={isLoading}
            onClick={() => setDefaultMethod(paymentMethod.id)} />
          <Button
            size="small"
            icon={Trash}
            color="red"
            label="Remove"
            loading={isLoading}
            onClick={() => removeMethod(paymentMethod.id)} />
        </div>
      </div>
    );

  } else {

    return (
      <div data-component="PaymentMethodCard">
        Unknown payment method type
        <div className="card-actions">
          <Button
            size="small"
            icon={isDefaultMethod ? Check : void 0}
            label={isDefaultMethod ? 'Default': 'Set as default'}
            disabled={isDefaultMethod}
            loading={isLoading}
            onClick={() => setDefaultMethod(paymentMethod.id)} />
          <Button
            size="small"
            icon={Trash}
            color="red"
            label="Remove"
            loading={isLoading}
            onClick={() => removeMethod(paymentMethod.id)} />
        </div>
      </div>
    );

  }

}

export default function BillingSubscriptionPage() {

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()
  const { paymentMethods } = useLoaderData() as BillingMethodsPageLoaderData;

  const addPaymentMethod = async () => {
    try {
      const result = await API.post('payments/payment_methods');
      if (result?.stripe_checkout_session_url) {
        window.location = result.stripe_checkout_session_url as any;
      } else {
        navigate('.', { replace: true })
      }
    } catch (e) {
      const error = e as Error;
      alert(error.message);
    }
  };

  return (
    <div data-component="BillingMethodsPage">
      <div className="billing-methods-actions">
        <Button
          size="small"
          icon={Plus}
          loading={isLoading}
          label="Add payment method"
          onClick={() => addPaymentMethod()} />
      </div>
      <div className="billing-methods">
        {paymentMethods.map(paymentMethod => {
          return <PaymentMethodCard paymentMethod={paymentMethod} key={paymentMethod.id} />;
        })}
      </div>
    </div>
  );

}