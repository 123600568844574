import { Link, useLocation } from "react-router-dom";

import "./Sidebar.scss";

interface SidebarLink {
  url: string,
  title: string
}

export default function Sidebar ({
  links
} : {
  links: SidebarLink[]
}) {

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div data-component="Sidebar">
      {links.map(link => {
        const isActive = pathname === link.url || pathname === link.url + '/';
        return (
          <div
            className={`sidebar-link ${isActive ? `sidebar-active` : ``}`}
            key={link.url}
          >
            <Link to={link.url}>{link.title}</Link>
          </div>
        );
      })}
    </div>
  );

}