import { LoaderFunctionArgs } from "react-router-dom";
import { SubscriptionPlanSchema } from "../../utils/types";
import API from "../../utils/api";

export interface PricingPageLoaderData {
  plans: SubscriptionPlanSchema[]
};

export default function PricingPageLoader() {
  return async ({ params } : LoaderFunctionArgs) => {
    return await API.get('payments/plans') as PricingPageLoaderData;
  };
};